import styled from '@emotion/styled'
import Link from 'next/link'

import { breakpoint } from '@/theme'
import ROUTES from '@helpers/routes'
import { CityPage } from '@stories/city-page/types'
import { TEST_IDS } from '@stories/top-cities-list/constants'

export type PropTypes = {
  topCities: CityPage[][]
  maxWidth?: string
}

function TopCitiesList(props: PropTypes): JSX.Element {
  const { topCities, maxWidth } = props

  if (!topCities.length) return null

  return (
    <Container maxWidth={maxWidth}>
      <Header data-e2eid={TEST_IDS.HEADER}>Top Cities</Header>
      <CityGrid>
        {topCities.map((topCitiesColumn, colIndex) => (
          <Column key={colIndex}>
            {topCitiesColumn.map((city) => (
              <CityGridItem key={city.slug}>
                <Link href={{ pathname: ROUTES.CITY_PAGE, query: { slug: city.slug } }} passHref>
                  <StyledLink data-e2eid={TEST_IDS.CITY_LINK}>{city.city}</StyledLink>
                </Link>
              </CityGridItem>
            ))}
          </Column>
        ))}
      </CityGrid>
      <SeeAllContainer>
        <Link href={ROUTES.WEED_DELIVERY} passHref>
          <SeeAllLink data-e2eid={TEST_IDS.SEE_ALL_LINK}>See All Cities</SeeAllLink>
        </Link>
      </SeeAllContainer>
    </Container>
  )
}

const Container = styled.section<{ maxWidth?: string }>`
  margin: 43px auto;
  max-width: ${({ maxWidth }) => maxWidth || `${breakpoint.min.xl}px`};
  padding: 0 20px;

  @media (min-width: ${breakpoint.min.xl}px) {
    padding: 0;
  }
`

const Header = styled.h2`
  font-size: ${({ theme }) => theme.typography.size.desktop.title2}px;
`

const CityGrid = styled.div`
  display: flex;
  gap: ${(props) => props.theme.spacing.medium}px;

  @media (max-width: ${breakpoint.max.md}px) {
    display: grid;
    grid-template-columns: 1fr;
    gap: 0;
  }
`

const Column = styled.ul`
  flex: 1;
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between; // Ensures even distribution of items

  @media (max-width: ${breakpoint.max.md}px) {
    display: contents; // Allows cities to flow like one long list
  }
`

const CityGridItem = styled.li`
  margin-bottom: ${({ theme }) => theme.spacing.smaller}px;
`

const SeeAllContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacing.medium}px;
  margin-bottom: ${({ theme }) => theme.spacing.xl}px;

  @media (max-width: ${breakpoint.max.md}px) {
    justify-content: flex-start;
  }
`

const SeeAllLink = styled.a`
  font-family: ${({ theme }) => theme.typography.font.simMono};
  font-size: ${({ theme }) => theme.typography.size.desktop.body2}px;
  color: ${({ theme }) => theme.colors.vibrantBlue};
  text-decoration: none;
  font-weight: bold;

  :hover {
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.black};
  }
`

const StyledLink = styled.a`

  :link {
    font-family: ${({ theme }) => theme.typography.font.simMono};
    color: ${({ theme }) => theme.colors.black};
    font-size: ${({ theme }) => theme.typography.size.desktop.body2}px;
    text-decoration: none;
  }

  :visited {
    color: ${({ theme }) => theme.colors.black};
  }
  :hover,
  :active {
    color: ${({ theme }) => theme.colors.vibrantBlue};
`

export default TopCitiesList
